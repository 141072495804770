<template>
  <div :class="bgColor">
    <!-- Footer -->
    <footer class="max-w-screen-xl px-5 2xl:px-0 mx-auto pt-8 pb-12">
      <div class="grid lg:grid-cols-12">
        <div class="lg:col-span-4 ">
          <div class="lg:max-w-[318px] space-y-6">
            <div class="flex items-center lg:items-start lg:flex-col gap-6">
              <h4 class="text-dark text-lg font-semibold">
                <a
                  href="/"
                  aria-label="Go home"
                  title="NoteForms"
                  class="inline-flex items-center"
                >
                  <img
                    src="/img/logo.svg"
                    alt="notion tools logo"
                    class="w-8 h-8 inline"
                  >
                  <span class="ml-2 text-xl font-bold tracking-wide text-gray-800 dark:text-gray-200">NoteForms</span>
                </a>
              </h4>
              <div class="flex gap-4 ml-6 md:ml-0">
                <a
                  :href="twitterUrl"
                  target="_blank"
                >
                  <img
                    src="/img/pages/welcome/twitter.svg"
                    alt="twitter"
                  >
                </a>
                <a
                  :href="facebookGroupUrl"
                  target="_blank"
                >
                  <img
                    src="/img/pages/welcome/facebook.svg"
                    alt="twitter"
                  >
                </a>
              </div>
            </div>
            <p class="text-gray-600 text-xs hidden lg:block">
              Notion and the Notion logo are trademarks of Notion Labs, Inc., and are used here with permission.
            </p>
            <p class="text-gray-600 text-xs hidden lg:block">
              NoteForms was previously known as NotionForms.
            </p>
            <p class="text-gray-600 text-xs hidden lg:block">
              © Copyright 2024 NoteForms. All rights reserved.
            </p>
          </div>
        </div>
        <div class="col-span-8 grid grid-cols-2 md:grid-cols-3 mt-12 lg:mt-0">
          <div>
            <h5 class="mb-5 font-semibold text-sm text-gray-800">
              Resources
            </h5>
            <ul class="mt-2 space-y-2">
              <li>
                <NuxtLink
                  :to="{name:'database-views'}"
                  class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue text-sm"
                >
                  Database Views
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  :to="{name:'form-templates'}"
                  class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue text-sm"
                >
                  Form Templates
                </NuxtLink>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://noteforms.com/resources?page=1&tag=use-cases"
                  class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue text-sm"
                >
                  Use Cases
                </a>
              </li>
              <li>
                <NuxtLink
                  :to="{name:'pricing'}"
                  class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue text-sm"
                >
                  Pricing
                </NuxtLink>
              </li>
              <li>
                <a
                  target="_blank"
                  :href="helpUrl"
                  class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue text-sm"
                >
                  Help
                </a>
              </li>
              <li>
                <NuxtLink
                  :to="{name:'features'}"
                  class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue text-sm"
                >
                  Features
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  :to="{name:'guides'}"
                  class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue text-sm"
                >
                  Guides
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  :to="{name:'integrations'}"
                  class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue text-sm"
                >
                  Integrations
                </NuxtLink>
              </li>
              <li
                id="changelog"
                data-canny-changelog
                class="relative block"
              >
                <a
                  id="changelog-trigger"
                  :href="changelogUrl"
                  target="_blank"
                  class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue cursor-pointer text-sm"
                >
                  Product Updates
                </a>
              </li>
              <li class="relative block">
                <a
                  target="_blank"
                  :href="featureRequestsUrl"
                  class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue text-sm"
                >
                  Feature Requests
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h5 class="mb-5 font-semibold text-sm text-gray-800">
              Community
            </h5>
            <ul class="mt-2 space-y-2">
              <li>
                <NuxtLink
                  :to="{name:'affiliates'}"
                  class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue text-sm"
                >
                  Affiliate Program
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  :to="{name:'discount-students-academics-ngos'}"
                  class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue text-sm"
                >
                  Student Discount
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  :to="{name:'discount-students-academics-ngos'}"
                  class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue text-sm"
                >
                  Academic Discount
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  :to="{name:'discount-students-academics-ngos'}"
                  class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue text-sm"
                >
                  NGO Discount
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  :to="{name:'partners'}"
                  class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue text-sm"
                >
                  Our Partners
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  :to="{name:'notion-ambassadors-influencers'}"
                  class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue text-sm"
                >
                  Notion Ambassadors
                </NuxtLink>
              </li>
              <li>
                <a
                  :href="testimonialUrl"
                  target="_blank"
                  class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue text-sm"
                >
                  Testimonials
                </a>
              </li>
              <li>
                <a
                  href="https://airformtable.com"
                  target="_blank"
                  class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue text-sm"
                >
                  For Airtable Users
                </a>
              </li>
            </ul>
          </div>
          <div class="mt-12 md:mt-0">
            <h5 class="mb-5 font-semibold text-sm text-gray-800">
              Legal
            </h5>
            <ul class="mt-2 space-y-2">
              <li>
                <NuxtLink
                  :to="{name:'privacy-policy'}"
                  class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue text-sm"
                >
                  Privacy Policy
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  :to="{name:'terms-conditions'}"
                  class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue text-sm"
                >
                  Terms & Conditions
                </NuxtLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="lg:hidden mt-12">
        <p class="text-gray-600 text-xs">
          Notion and the Notion logo are trademarks of Notion Labs, Inc., and are used here with permission.
        </p>
        <p class="text-gray-600 text-xs">
          NoteForms was previously known as NotionForms.
        </p>
        <p class="text-gray-600 text-xs mt-6">
          © Copyright 2024 NoteForms. All rights reserved.
        </p>
      </div>
    </footer>
  </div>
</template>

<script setup>
import noteformsConfig from '~/noteforms.config'

const config = noteformsConfig
defineProps({
  bgColor: {
    type: String,
    default: 'bg-white'
  }
})

const helpUrl = computed(() => config.links.help_url)
const testimonialUrl = computed(() => config.links.testimonial)
const changelogUrl = computed(() => config.links.changelog_url)
const facebookGroupUrl = computed(() => config.links.facebook_group)
const twitterUrl = computed(() => config.links.twitter)
const featureRequestsUrl = computed(() => config.links.feature_requests)
</script>
